import classNames from "classnames"
import React from "react"
import { Link } from "react-router-dom"

import "./Button.scss"

function Button(props) {
  const {
    text = "Button",
    style = "",
    className,
    linkTo = null,
    ...rest
  } = props

  const usedClassName = classNames("Button", style, className)

  if (linkTo) {
    return (
      <Link className={usedClassName} to={linkTo} {...rest}>
        {text}
      </Link>
    )
  }

  return (
    <button className={usedClassName} {...rest}>
      {text}
    </button>
  )
}

export default Button

import React, { useState } from "react"
import { useHistory, useParams } from "react-router-dom"
import axios from "axios"
import { useDispatch } from "react-redux"
import { setUser, createAlert } from "~/store"
import Heading from "~/components/Heading/Heading"
import Button from "~/components/Button/Button"
import TextField from "~/components/TextField/TextField"
import PageContent from "~/components/PageContent/PageContent"

import "./AcceptInvite.scss"

function AcceptInvite() {
  const dispatch = useDispatch()
  const [creating, setCreating] = useState(false)
  const [name, setName] = useState("")
  const [password, setPassword] = useState("")
  const [passwordConfirmation, setPasswordConfirmation] = useState("")
  const { invitationToken } = useParams()
  let history = useHistory()

  const queryParams = new URLSearchParams(window.location.search)
  const organisation = queryParams.get("organisation")
  const invitedBy = queryParams.get("invitedBy")

  const userSignUp = () => {
    setCreating(true)

    const params = {
      user: {
        name: name,
        password: password,
        password_confirmation: passwordConfirmation,
        invitation_token: invitationToken,
      },
    }

    const token = document.querySelector("[name=csrf-token]").content

    axios
      .put("/users/invitation", params, {
        headers: { "X-CSRF-TOKEN": token },
      })
      .then((response) => {
        const userData = response.data["data"]["attributes"]
        dispatch(setUser(userData))
        history.push("/spaces")
      })
      .catch((error) => {
        dispatch(
          createAlert({
            message: error.response.data.error.join(", "),
            type: "error",
          })
        )
        setCreating(false)
      })
  }

  return (
    <PageContent>
      <div className="AcceptInvite">
        <Heading text="Welcome to Pomodoro" />
        <p>
          You've been invited {organisation ? `to ${organisation}` : ""}{" "}
          {invitedBy ? `by ${invitedBy}` : ""}
        </p>

        <form>
          <TextField
            label="What's your full name?"
            id="signup_name"
            value={name}
            valueChanged={(value) => {
              setName(value)
            }}
            regex={/[&/\\#,$~%.":*{}]/g}
            maxLength="50"
          />

          <TextField
            label="Password"
            id="signup_password"
            type="password"
            value={password}
            valueChanged={(value) => {
              setPassword(value)
            }}
          />

          <TextField
            label="Repeat password"
            id="signup_repeat_password"
            type="password"
            value={passwordConfirmation}
            valueChanged={(value) => {
              setPasswordConfirmation(value)
            }}
          />

          <div className="AcceptInvite__actions mt-20 mb-20">
            <span>
              By signing up, you agree to our{" "}
              <a
                href="https://www.abtion.com/privatlivspolitik"
                target="_blank"
              >
                Privacy Policy
              </a>
            </span>
            <Button
              text="Continue"
              style="primary"
              onClick={userSignUp}
              disabled={creating}
            />
          </div>
        </form>
      </div>
    </PageContent>
  )
}

export default AcceptInvite

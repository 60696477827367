import React, { useState } from "react"
import axios from "axios"
import { getUser } from "~/store"
import { useSelector } from "react-redux"
import User from "~/components/User/User"
import IconEdit from "../Icons/iconEdit"
import Checkbox from "~/components/Checkbox/Checkbox"
import Button from "~/components/Button/Button"
import "./SettingsUsersTableRow.scss"

function SettingsUsersTableRow(props) {
  const user = useSelector(getUser)
  const [editActive, setEditActive] = useState(false)
  const [deletedRow, setDeletedRow] = useState(false)
  const [rowUser, setRowUser] = useState(props.user.attributes)
  const editAllowed =
    !rowUser.pending_invitation && !(user.id == rowUser.id) && user.admin

  const { index } = props

  const updateUserRole = (value) => {
    const params = {
      user: {
        admin: value,
      },
    }

    axios
      .put(`/api/organisations/users/${rowUser.id}`, params)
      .then((response) => {
        const userData = response.data["data"]["attributes"]
        setRowUser(userData)
      })
      .catch((error) => {
        console.log(error)
      })
      .then(() => {
        setEditActive(false)
      })
  }

  const removeUserFromOrganisation = () => {
    if (
      window.confirm(
        `Are you sure you want to remove ${rowUser.name} from ${rowUser.organisation_name}`
      )
    ) {
      axios
        .delete(`/api/organisations/users/${rowUser.id}`)
        .then((response) => {
          setDeletedRow(true)
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }
  if (deletedRow) return null

  return (
    <tr className="SettingsUsersTableRow" key={index}>
      {/* Column 1 - User name */}
      <td>
        <div className="SettingsUsersTableRow__name">
          <User
            key={index}
            name={rowUser.name}
            picture={rowUser.picture}
            className="SettingsUsersTableRow__picture"
          />
          <span>{rowUser.name ? rowUser.name : rowUser.email}</span>
        </div>
      </td>
      {editActive ? (
        <>
          {/* Column 2 - Role  */}
          <td className="SettingsUsersTableRow__toggle-admin">
            <Checkbox
              label="Admin"
              id={`admin-${index}`}
              value={rowUser.admin}
              checked={String(rowUser.admin)}
              valueChanged={(newValue) => updateUserRole(newValue)}
            />
          </td>
          {/* Column 3 - remove */}
          <td>
            <Button
              style="outline"
              className="SettingsUsersTableRow__remove-user"
              onClick={removeUserFromOrganisation}
              text="Remove"
            />
          </td>
        </>
      ) : (
        <>
          {/* Column 2 - Role */}
          <td>
            {rowUser.pending_invitation ? (
              "Pending invitation"
            ) : (
              <>{rowUser.admin ? "Admin" : "User"}</>
            )}
          </td>
          {/* Column 3 - remove */}
          <td></td>
        </>
      )}
      {editAllowed ? (
        /* Column 4 - edit */
        <td>
          <span
            className="SettingsUsersTableRow__edit-user"
            onClick={() => setEditActive(!editActive)}
          >
            <IconEdit />
          </span>
        </td>
      ) : (
        /* Column 4 - edit */
        <td></td>
      )}
    </tr>
  )
}

SettingsUsersTableRow.defaultProps = {
  user: {},
}

export default SettingsUsersTableRow

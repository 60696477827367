import React from "react"

import "./TimerOption.scss"

function TimerOption(props) {
  return (
    <div
      className={`TimerOption TimerOption--${
        props.cycleIsBreak ? "break" : "work"
      }`}
      onClick={() => props.onClick(props.minutes)}
    >
      <span className="TimerOption__minutes">{props.minutes}</span>
      <span className="TimerOption__label">minutes</span>
    </div>
  )
}

TimerOption.defaultProps = {
  onClick: (_minutes) => {},
  cycleIsBreak: false,
}

export default TimerOption

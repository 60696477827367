import React, { useState } from "react"

import "~/components/Label/Label"
import "./Slider.scss"
import Label from "~/components/Label/Label"
import { playAlarm } from "~/utils/AlarmUtils"

function Slider(props) {
  const [value, setValue] = useState(props.value)

  const handleChange = (event) => {
    playAlarm(event.target.value)
    setValue(event.target.value)
    if (props.valueChanged) {
      props.valueChanged(event.target.value)
    }
  }

  const { label, disabled } = props

  return (
    <div className="Slider">
      <Label text={label} />
      <input
        className="Slider__input"
        type="range"
        min="0.1"
        max="0.9"
        step="0.1"
        value={value}
        onChange={handleChange}
        disabled={disabled}
      />
    </div>
  )
}

export default Slider

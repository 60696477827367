import React, { useState } from "react"
import { NavLink } from "react-router-dom"
import { useDispatch } from "react-redux"
import Heading from "~/components/Heading/Heading"
import Button from "~/components/Button/Button"
import TextField from "~/components/TextField/TextField"
import PageContent from "~/components/PageContent/PageContent"
import axios from "axios"
import { setUser, createAlert } from "~/store"
import { useParams } from "react-router-dom"

import "./EditPassword.scss"

function EditPassword() {
  const dispatch = useDispatch()
  const [password, setPassword] = useState("")
  const [passwordConfirmation, setPasswordConfirmation] = useState("")
  const [saving, setSaving] = useState(false)
  const { resetPasswordToken } = useParams()

  const setNewPassword = () => {
    setSaving(true)

    const token = document.querySelector("[name=csrf-token]").content

    axios
      .put(
        "/users/password",
        {
          reset_password_token: resetPasswordToken,
          user: {
            password: password,
            password_confirmation: passwordConfirmation,
          },
        },
        { headers: { "X-CSRF-TOKEN": token } }
      )
      .then((response) => {
        const userData = response.data["data"]["attributes"]
        dispatch(setUser(userData))
      })
      .catch((error) => {
        setSaving(false)
        dispatch(
          createAlert({
            message: error.response.data.error,
            type: "error",
          })
        )
      })
  }

  return (
    <PageContent center={false}>
      <div className="EditPassword">
        <Heading text="Create a new password" />

        <div className="EditPassword__description">
          Please enter and confirm your new password.
        </div>

        <form>
          <TextField
            label="Password"
            id="user_password"
            name="user[password]"
            type="password"
            value={password}
            valueChanged={setPassword}
            autofocus={true}
          />

          <TextField
            label="Repeat password"
            id="user_password_confirmation"
            name="user[password_confirmation]"
            type="password"
            value={passwordConfirmation}
            valueChanged={setPasswordConfirmation}
          />

          <div className="EditPassword__wrapper mt-20 mb-20">
            <Button
              text="Reset"
              style="primary float-right"
              onClick={setNewPassword}
              disabled={saving}
            />
          </div>
        </form>

        <div className="EditPassword__sign-in">
          <NavLink to="/" className="EditPassword__sign-in-link">
            Sign in to <span className="font-weight-bold">Pomodoro</span>
          </NavLink>
        </div>
      </div>
    </PageContent>
  )
}

export default EditPassword

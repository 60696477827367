import React, { useState } from "react"
import axios from "axios"
import { useSelector, useDispatch } from "react-redux"
import { getUser, setUser } from "~/store"
import Button from "~/components/Button/Button"
import Footer from "~/components/Footer/Footer"
import Heading from "~/components/Heading/Heading"
import SettingsMenu from "~/components/SettingsMenu/SettingsMenu"
import Sidebar from "~/components/Sidebar/Sidebar"
import TextField from "~/components/TextField/TextField"
import PageContent from "~/components/PageContent/PageContent"
import PageWithSidebar from "~/components/PageWithSidebar/PageWithSidebar"
import { createAlert } from "~/store"
import "./SettingsOrganisation.scss"

function SettingsOrganisation() {
  const user = useSelector(getUser)
  const dispatch = useDispatch()
  const [updating, setUpdating] = useState(false)
  const [organisationName, setOrganisationName] = useState(
    user.organisation_name
  )

  const updateOrganisation = (event) => {
    event.preventDefault()

    setUpdating(true)

    const params = {
      organisation: {
        name: organisationName,
      },
    }

    axios
      .put(`/api/organisations/${user.organisation_id}`, params)
      .then((response) => {
        const userData = response.data["data"]["attributes"]
        dispatch(setUser(userData))
        dispatch(
          createAlert({
            message: `Organisation name has been updated to "${organisationName}"`,
            type: "success",
          })
        )

        setUpdating(false)
      })
      .catch((error) => {
        dispatch(
          createAlert({
            message: error.response.data.error.join(", "),
            type: "error",
          })
        )
        setUpdating(false)
      })
  }

  return (
    <PageWithSidebar>
      <PageContent center={false}>
        <div className="SettingsOrganisation">
          <Heading text="Settings" />

          <div className="SettingsOrganisation__content">
            <SettingsMenu />
            <form>
              <TextField
                label="Organisation name"
                placeholder="Your organisation's name"
                value={organisationName}
                valueChanged={setOrganisationName}
                disabled={!user.admin}
                maxLength="50"
              />
              {user.admin && (
                <Button
                  text={updating ? "Updating..." : "Update details"}
                  style="primary mt-20 float-right"
                  onClick={updateOrganisation}
                  disabled={
                    updating || organisationName == user.organisation_name
                  }
                />
              )}
            </form>
          </div>
        </div>
        <Footer style="light" />
      </PageContent>
      <Sidebar style="primary" />
    </PageWithSidebar>
  )
}

export default SettingsOrganisation

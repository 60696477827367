import React from "react"

import "./Footer.scss"

function Footer(props) {
  return (
    <div className={`Footer Footer--${props.style}`}>
      <ul>
        <li>
          <a href="https://www.abtion.com/sikkerhedspolitik">
            Disclosure policy
          </a>
        </li>
      </ul>
    </div>
  )
}

export default Footer

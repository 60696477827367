import React, { useState } from "react"
import { NavLink } from "react-router-dom"
import Heading from "~/components/Heading/Heading"
import TextField from "../../components/TextField/TextField"
import Button from "~/components/Button/Button"
import Footer from "~/components/Footer/Footer"
import PageContent from "~/components/PageContent/PageContent"
import { useDispatch } from "react-redux"
import { createAlert } from "~/store"
import axios from "axios"

import "./SignIn.scss"

function SignIn() {
  const dispatch = useDispatch()
  const [signingIn, setSigningIn] = useState(false)
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")

  const signInPassword = () => {
    setSigningIn(true)

    const token = document.querySelector("[name=csrf-token]").content

    axios
      .post(
        "/users/sign_in",
        { user: { email, password } },
        { headers: { "X-CSRF-TOKEN": token } }
      )
      .then((response) => {
        // const userData = response.data["data"]["attributes"]
        // dispatch(setUser(userData))
        window.location.reload(false)
      })
      .catch((error) => {
        dispatch(
          createAlert({
            message: error.response.data.error,
            type: "error",
          })
        )
        setSigningIn(false)
      })
  }

  const signInSSO = (url) => {
    var form = document.createElement("form")
    document.body.appendChild(form)
    form.setAttribute("method", "POST")
    form.setAttribute("action", url)

    var input = document.createElement("input")
    input.type = "hidden"
    input.name = "authenticity_token"
    input.value = document.head.querySelector("[name='csrf-token']").content

    form.appendChild(input)
    form.submit()
  }

  return (
    <PageContent>
      <div className="SignIn">
        <Heading text="Sign in" />
        {process.env.SIGN_IN_WITH_EMAIL === "true" && (
          <>
            <form id="SignIn__form">
              <TextField
                label="Email address"
                id="user_email"
                name="user[email]"
                type="email"
                autofocus={true}
                value={email}
                valueChanged={setEmail}
              />

              <TextField
                label="Password"
                id="user_password"
                name="user[password]"
                type="password"
                value={password}
                valueChanged={setPassword}
              />

              <div className="SignIn__actions mt-20 mb-20">
                <span>
                  Forgot your password?{" "}
                  <NavLink to="/password/new">Reset here</NavLink>
                </span>
                <Button
                  style="primary"
                  type="submit"
                  text="Continue"
                  onClick={signInPassword}
                  disabled={signingIn}
                />
              </div>
            </form>
            <div className="SignIn__social-login">
              <Button
                style="outline mb-10"
                text="Sign up with email"
                linkTo="/signup"
              />
            </div>
          </>
        )}
        <div className="SignIn__social-login">
          {process.env.SIGN_IN_WITH_GOOGLE === "true" && (
            <Button
              style="SignIn__google-button mb-10"
              text="Sign in with Google"
              onClick={() => signInSSO("/users/auth/google_oauth2")}
            />
          )}

          {process.env.SIGN_IN_WITH_MICROSOFT === "true" && (
            <Button
              style="SignIn__microsoft-button"
              text="Sign in with Microsoft"
              onClick={() => signInSSO("/users/auth/microsoft_graph")}
            />
          )}
        </div>
      </div>

      <Footer style="light" />
    </PageContent>
  )
}

export default SignIn

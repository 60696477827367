export const sortSpaces = (spaces, user) => {
  return spaces.sort((space) => {
    return space.users.filter((u) => u.email == user.email).length > 0 ? -1 : 1
  })
}

const keywordsForSpace = (space) => {
  let keywords = []
  keywords.push(space.name)
  space.users.forEach((user) => {
    keywords.push(user.name)
    keywords.push(user.email)
  })
  return keywords.map((keyword) => keyword.toLowerCase())
}

export const filteredSpaces = (spaces, search) => {
  if (!spaces) return []

  return spaces.filter((space) => {
    const keywords = keywordsForSpace(space)
    return (
      search == "" ||
      keywords.filter((keyword) => keyword.includes(search.toLowerCase()))
        .length > 0
    )
  })
}

export const getSpaceForUser = (spaces, user) => {
  return spaces.find((space) => {
    return space.users.find((u) => u.email == user.attributes.email)
  })
}

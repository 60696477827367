import React, { useState } from "react"
import axios from "axios"
import { useSelector, useDispatch } from "react-redux"
import { getUser, setUser, createAlert } from "~/store"
import { getLocations } from "~/utils/Locations"
import Sidebar from "~/components/Sidebar/Sidebar"
import Footer from "~/components/Footer/Footer"
import Heading from "~/components/Heading/Heading"
import Button from "~/components/Button/Button"
import TextField from "~/components/TextField/TextField"
import Checkbox from "~/components/Checkbox/Checkbox"
import Slider from "~/components/Slider/Slider"
import Dropdown from "~/components/Dropdown/Dropdown"
import SettingsMenu from "~/components/SettingsMenu/SettingsMenu"
import PageContent from "~/components/PageContent/PageContent"
import PageWithSidebar from "~/components/PageWithSidebar/PageWithSidebar"

import "./SettingsProfile.scss"

function SettingsProfile() {
  const user = useSelector(getUser)
  const dispatch = useDispatch()
  const [updating, setUpdating] = useState(false)
  const [luxaforToken, setLuxaforToken] = useState(user.luxafor_token || "")
  const [location, setLocation] = useState(user.location)
  const [alert, setAlert] = useState(user.alert)
  const [alertVolume, setAlertVolume] = useState(user.alert_volume)

  const updateUser = () => {
    setUpdating(true)

    const params = {
      user: {
        luxafor_token: luxaforToken,
        location: location,
        alert: alert,
        alert_volume: alertVolume,
      },
    }

    axios
      .put(`/api/users/${user.id}`, params)
      .then((response) => {
        const userData = response.data["data"]["attributes"]
        dispatch(setUser(userData))
        dispatch(
          createAlert({
            message: "User settings updated",
            type: "success",
          })
        )
      })
      .catch((error) => {
        dispatch(
          createAlert({
            message: error.message,
            type: "error",
          })
        )
      })
      .then(() => {
        setUpdating(false)
      })
  }

  return (
    <PageWithSidebar>
      <PageContent center={false}>
        <div className="SettingsProfile">
          <Heading text="Settings" />

          <div className="SettingsProfile__content">
            <SettingsMenu />
            <div className="SettingsProfile__form">
              <TextField
                label="Luxafor ID"
                placeholder="E.g. 8844554466"
                value={luxaforToken}
                valueChanged={setLuxaforToken}
                regex={/[&/\\#,$~%.":*{}]/g}
              />
              <Dropdown
                ariaLabel="Location dropdown"
                label="Location"
                value={user.location || ""}
                options={getLocations("Select a location...")}
                valueChanged={(newValue) => setLocation(newValue)}
              />
              {!user.location && (
                <span className="SettingsProfile__location_warning">
                  ⚠️ Please select your location
                </span>
              )}
              <Checkbox
                label="Alert"
                id="switch-alert"
                value={user.alert}
                checked={String(user.alert)}
                valueChanged={(newValue) => setAlert(newValue)}
              />
              <Slider
                label="Alert volume"
                value={user.alert_volume}
                valueChanged={(newValue) => setAlertVolume(newValue)}
                disabled={!alert}
              />
              <Button
                text={updating ? "Updating..." : "Update details"}
                style="primary mt-20 float-right"
                onClick={updateUser}
                disabled={updating}
              />
            </div>
          </div>
        </div>
        <Footer style="light" />
      </PageContent>
      <Sidebar style="primary" />
    </PageWithSidebar>
  )
}

export default SettingsProfile

import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { getAlerts } from "~/store"
import "./Notification.scss"

function Notification() {
  const alerts = useSelector(getAlerts)
  const [alert, setAlert] = useState({ type: "", message: "" })
  const [show, setShow] = useState(false)

  useEffect(() => {
    if (alerts.length > 0) {
      setAlert(alerts[alerts.length - 1])
      setShow(true)
    }
  }, [alerts])

  const onClose = () => {
    setShow(false)
  }

  return show ? (
    <div className={`Notification Notification--${alert.type || "error"}`}>
      <div className="Notification__text">{alert.message || ""}</div>

      <div className="Notification__button" onClick={onClose}>
        Close me
      </div>
    </div>
  ) : (
    ""
  )
}

export default Notification

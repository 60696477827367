import React from "react"

import Heading from "~/components/Heading/Heading"
import RollingTomato from "~/components/RollingTomato/RollingTomato"

import "./TomatoRoll.scss"

class TomatoRoll extends React.Component {
  render() {
    return (
      <div className="TomatoRoll">
        <RollingTomato />
        <Heading text="pomodoro" />
      </div>
    )
  }
}

export default TomatoRoll

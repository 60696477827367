import React from "react"
import { NavLink } from "react-router-dom"
import { useSelector } from "react-redux"

import "./Sidebar.scss"

import IconList from "~/components/Icons/IconList"
import IconSettings from "~/components/Icons/IconSettings"
import IconExit from "~/components/Icons/IconExit"
import IconDashboard from "~/components/Icons/IconDashboard"
import Tooltip from "~/components/Tooltip/Tooltip"
import { getUser } from "~/store"

function Sidebar(props) {
  return (
    <nav className={`Sidebar Sidebar--${props.style}`}>
      <div className="Sidebar__icons">
        {props.onSpacePage ? (
          <Tooltip
            className="Sidebar__icon Sidebar__icon--leave"
            text="Exit space"
          >
            <a onClick={props.onLeaveButtonClick}>
              <IconExit />
            </a>
          </Tooltip>
        ) : null}
        <Tooltip className="Sidebar__icon" text="Spaces">
          <NavLink to="/spaces" activeClassName="selected" exact={true}>
            <IconList />
          </NavLink>
        </Tooltip>
        <Tooltip className="Sidebar__icon" text="Dashboard">
          <NavLink to="/dashboard" activeClassName="selected">
            <IconDashboard />
          </NavLink>
        </Tooltip>
        <Tooltip className="Sidebar__icon" text="Settings">
          <NavLink to="/settings" activeClassName="selected">
            <IconSettings />
          </NavLink>
        </Tooltip>
      </div>
    </nav>
  )
}

Sidebar.defaultProps = {
  onSpacePage: false,
}

export default Sidebar

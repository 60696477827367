require("@rails/ujs").start()
require("@rails/activestorage").start()

require.context("../images", true)

require("../stylesheets/application.scss")

require("../channels")

import React from "react"
import { createRoot } from "react-dom/client"

import App from "~/App"

document.addEventListener("DOMContentLoaded", () => {
  const root = createRoot(document.getElementById("app"))
  root.render(<App />)
})

import React from "react"

import User from "~/components/User/User"
import "./UserGroup.scss"

function UserGroup({ hideTitle = true, align = "center", users = [] }) {
  return (
    <div className="UserGroup">
      {hideTitle ? "" : <h4>Members</h4>}
      <div className={`UserGroup__pictures UserGroup__pictures--${align}`}>
        {users.map((user, index) => {
          if (!user) return

          return (
            <User
              key={index}
              name={user.name}
              picture={user.picture}
              className="UserGroup__picture"
            />
          )
        })}
      </div>
    </div>
  )
}

export default UserGroup

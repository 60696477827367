import React, { useState } from "react"
import TextField from "~/components/TextField/TextField"
import IconSearch from "../Icons/IconSearch"
import "./SearchField.scss"

function SearchField(props) {
  const [active, setActive] = useState(false)
  const [value, setValue] = useState("")

  const handleChange = (value) => {
    setValue(value)
    props.onChange(value)
  }

  return (
    <div className="SearchField">
      {active ? (
        <TextField
          style={props.style}
          value={value}
          autofocus={true}
          placeholder="Search..."
          valueChanged={(value) => {
            handleChange(value)
          }}
        />
      ) : (
        <button
          className={`SearchField__button ${props.style}`}
          onClick={() => setActive(true)}
        >
          <IconSearch />
        </button>
      )}
    </div>
  )
}

SearchField.defaultProps = {
  onChange: () => {},
  style: "light",
}

export default SearchField

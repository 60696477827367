import React from "react"
import { useSelector } from "react-redux"
import { getUser } from "~/store"
import UserGroup from "~/components/UserGroup/UserGroup"
import Countdown from "~/components/Countdown/Countdown"
import { Link } from "react-router-dom"
import { calculateDurationLeft } from "~/utils/TimerUtils"

import "./SpaceCard.scss"

const SpaceCard = (props) => {
  const space = props.attributes
  let cardClassName = "SpaceCard "
  const timer = space.current_timer
  const timerExists = timer != null
  const user = useSelector(getUser)
  const isCurrentSpace = space.name == user.space_name

  if (timerExists) {
    const timerClass = timer.break ? "SpaceCard--break" : "SpaceCard--work"
    cardClassName += timerClass
  }

  return (
    <div className={cardClassName}>
      <div className="SpaceCard__wrapper">
        <div className="SpaceCard__top">
          <div className="SpaceCard__title">{space.name}</div>
          <div className="SpaceCard__countdown">
            {timerExists && calculateDurationLeft(timer) > 0 ? (
              <Countdown
                timer={timer}
                spaceName={space.name}
                onCountDownEnd={() => void 0}
              />
            ) : (
              ""
            )}
          </div>
          <div className="SpaceCard__users">
            <UserGroup align="left" users={space.users} />
          </div>
        </div>

        <Link to={`/spaces/${space.name}`}>
          {isCurrentSpace ? "Open space" : "Join space"}
        </Link>
      </div>
    </div>
  )
}

export default SpaceCard

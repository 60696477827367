import React, { useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Redirect } from "react-router-dom"

import Heading from "~/components/Heading/Heading"
import Button from "~/components/Button/Button"
import Sidebar from "~/components/Sidebar/Sidebar"
import TextField from "~/components/TextField/TextField"
import { generateRandomSpaceName } from "~/utils/NameGenerator"
import PageWithSidebar from "~/components/PageWithSidebar/PageWithSidebar"
import PageContent from "~/components/PageContent/PageContent"
import axios from "axios"
import { getUser, setUser, createAlert } from "~/store"

function NewSpace() {
  const user = useSelector(getUser)
  const dispatch = useDispatch()
  const setStateUser = (user) => dispatch(setUser(user))
  const [redirect, setRedirect] = useState(false)
  const [spaceName, setSpaceName] = useState(generateRandomSpaceName())
  const [creating, setCreating] = useState(false)

  const createSpace = (event) => {
    event.preventDefault()

    setCreating(true)

    const params = {
      space: {
        name: spaceName,
      },
    }

    axios
      .post("/api/spaces", params)
      .then((_response) => {
        setStateUser({ ...user, space_name: spaceName })
        setRedirect(true)
      })
      .catch((error) => {
        dispatch(
          createAlert({
            message: error.response.data.error.join(", "),
            type: "error",
          })
        )

        setCreating(false)
      })
  }

  return redirect ? (
    <Redirect to={`/spaces/${spaceName}`} />
  ) : (
    <PageWithSidebar>
      <PageContent>
        <div>
          <Heading text="Let's create a new space" />
          <form>
            <TextField
              label="Space name"
              placeholder="my-space-name"
              value={spaceName}
              valueChanged={(newValue) => setSpaceName(newValue)}
              autofocus={true}
              regex={/[&/\\#,$~%.":*{}]/g}
              maxLength="50"
            />
            <Button
              text={creating ? "Creating..." : "Create"}
              style="primary mt-20 float-right"
              onClick={createSpace}
              disabled={creating}
            />
          </form>
        </div>
      </PageContent>
      <Sidebar style="primary" />
    </PageWithSidebar>
  )
}

export default NewSpace

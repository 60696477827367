import React, { useState } from "react"
import { useSelector } from "react-redux"
import SpaceCard from "~/components/SpaceCard/SpaceCard"
import SpaceCreateCard from "~/components/SpaceCreateCard/SpaceCreateCard"
import SpaceLoadingCard from "~/components/SpaceLoadingCard/SpaceLoadingCard"
import SpaceNotFoundCard from "~/components/SpaceNotFoundCard/SpaceNotFoundCard"
import Heading from "~/components/Heading/Heading"
import Sidebar from "~/components/Sidebar/Sidebar"
import Button from "~/components/Button/Button"
import { useHistory } from "react-router-dom"
import { sortSpaces, filteredSpaces } from "~/utils/SpaceUtils"
import { getUser, getSpaces } from "~/store"
import SearchField from "../../components/SearchField/SearchField"
import PageContent from "~/components/PageContent/PageContent"
import PageWithSidebar from "~/components/PageWithSidebar/PageWithSidebar"

import "./Spaces.scss"

function Spaces() {
  const user = useSelector(getUser)
  const spaces = useSelector(getSpaces)
  const [search, setSearch] = useState("")
  let history = useHistory()
  const loaded = Array.isArray(spaces)

  const placeholderCards = [1, 2, 3, 4, 5, 6].map((key) => {
    return <SpaceLoadingCard key={key} />
  })

  const spaceCards = sortSpaces(filteredSpaces(spaces, search), user).map(
    (space) => {
      return <SpaceCard key={space.name} attributes={space} />
    }
  )

  return (
    <PageWithSidebar>
      <PageContent style="light">
        <div className="Spaces">
          <div className="Spaces__header">
            <Heading text={"Hi " + user.name} />
            {user.organisation_name ? (
              <SearchField
                style="light"
                onChange={(value) => {
                  setSearch(value)
                }}
              />
            ) : (
              <Button
                type="button"
                text="Start collaborating"
                style="light mr-10"
                onClick={() => history.push("/organisations/new")}
              />
            )}
          </div>
          <div className="Spaces__scrollable">
            {!loaded ? placeholderCards : null}
            {loaded ? <SpaceCreateCard /> : ""}
            {loaded && spaces.length > 0 && spaceCards.length == 0 ? (
              <SpaceNotFoundCard />
            ) : (
              spaceCards
            )}
          </div>
        </div>
      </PageContent>
      <Sidebar style="secondary" />
    </PageWithSidebar>
  )
}

export default Spaces

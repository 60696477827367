import React, { useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { getUser, createAlert } from "~/store"
import Heading from "~/components/Heading/Heading"
import axios from "axios"

import "./EmailVerification.scss"
import PageContent from "../../components/PageContent/PageContent"

function EmailVerification() {
  const dispatch = useDispatch()
  const user = useSelector(getUser)

  const resendVarificationMail = (e) => {
    e.preventDefault()

    const token = document.querySelector("[name=csrf-token]").content

    axios
      .post(
        "/users/confirmation",
        { user: { email: user.email } },
        { headers: { "X-CSRF-TOKEN": token } }
      )
      .then((_response) => {
        dispatch(
          createAlert({
            message: "Email has been sent.",
            type: "success",
          })
        )
      })
      .catch((_error) => {
        dispatch(
          createAlert({
            message: "Something went wrong...",
            type: "error",
          })
        )
      })
  }

  return (
    <PageContent className="EmailVerification__page-content">
      <div className="EmailVerification">
        <Heading text="Please verify your email" />
        <span className="EmailVerification__description">
          Once you verify your email address, you and your team can get started
          in Pomodoro.
        </span>
        <span className="EmailVerification__resend-email">
          Didn't recieve an email?{" "}
          <a href="" onClick={resendVarificationMail}>
            Resend email.
          </a>
        </span>
      </div>
    </PageContent>
  )
}

export default EmailVerification

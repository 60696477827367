import React from "react"
import DashboardTableRow from "~/components/DashboardTableRow/DashboardTableRow"

import "./DashboardTable.scss"

import { getSpaceForUser } from "../../utils/SpaceUtils"
import { useSelector } from "react-redux"
import { getSpaces } from "~/store"

function DashboardTable(props) {
  const spaces = useSelector(getSpaces) ?? []

  const userRows = props.users.map((user, index) => {
    const space = getSpaceForUser(spaces, user)
    return (
      <DashboardTableRow
        index={index}
        user={user}
        key={user.id}
        timer={space ? space.current_timer : undefined}
      />
    )
  })

  return (
    <table className="DashboardTable">
      <thead>
        <tr>
          <th>Name</th>
          <th>Pomodoros today</th>
          <th>Pomodoros this week</th>
          <th>Time left</th>
        </tr>
      </thead>
      <tbody>{userRows}</tbody>
    </table>
  )
}

DashboardTable.defaultProps = {
  users: [],
}

export default DashboardTable

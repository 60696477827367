import React from "react"

import "./Heading.scss"

class Heading extends React.Component {
  render() {
    return <h1 className="Heading">{this.props.text}</h1>
  }
}

export default Heading

import React, { useState } from "react"
import { useDispatch } from "react-redux"
import Heading from "~/components/Heading/Heading"
import Button from "~/components/Button/Button"
import { useHistory } from "react-router-dom"
import TextField from "~/components/TextField/TextField"
import axios from "axios"
import { setUser, createAlert } from "~/store"

import "./NewOrganisation.scss"
import PageContent from "../../components/PageContent/PageContent"

function NewOrganisation() {
  const dispatch = useDispatch()
  const [organisationName, setOrganisationName] = useState("")
  const [creating, setCreating] = useState(false)

  let history = useHistory()

  const createOrganisation = (event) => {
    event.preventDefault()

    setCreating(true)

    const params = {
      organisation: {
        name: organisationName,
      },
    }

    axios
      .post("/api/organisations", params)
      .then((_response) => {
        const userData = _response.data["data"]["attributes"]
        dispatch(setUser(userData))

        history.push("/organisations/invite")
      })
      .catch((error) => {
        setCreating(false)
        dispatch(
          createAlert({
            message: error.response.data.error,
            type: "error",
          })
        )
      })
  }

  return (
    <PageContent>
      <div className="NewOrganization">
        <Heading text="You need an organisation" />

        <span className="NewOrganization__description">
          To start collaborating you need to create an organisation
        </span>

        <form>
          <TextField
            label="Name your organisation"
            placeholder="Company inc."
            value={organisationName}
            valueChanged={(newValue) => setOrganisationName(newValue)}
            regex={/[&/\\#,$~%.":*{}]/g}
            autofocus={true}
            maxLength="50"
          />

          <div className="NewOrganization__actions mt-20 mb-20">
            <Button
              type="button"
              text="Back"
              style="dark primary mr-10"
              onClick={() => history.push("/spaces")}
            />
            <Button
              type="submit"
              text={creating ? "Creating..." : "Create"}
              style="primary"
              onClick={createOrganisation}
              disabled={creating}
            />
          </div>
        </form>
      </div>
    </PageContent>
  )
}

export default NewOrganisation

import classNames from "classnames"
import React from "react"
import { createImageFromInitials } from "../../utils/UserInitialsUtils"
import "./User.scss"

function User({ name, picture, className, ...rest }) {
  const usedClassName = classNames("User", className)

  return (
    <div
      className={usedClassName}
      title={name}
      style={{
        background:
          "#f2d7ca url(" +
          (picture !== null ? picture : createImageFromInitials(500, name)) +
          ") 0% 0% / cover",
      }}
      {...rest}
    />
  )
}

export default User

import React from "react"

function IconArrowRight() {
  return (
    <svg
      width="8"
      height="14"
      viewBox="0 0 8 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M1 13L7 7L0.999999 1" stroke="#FCFAF0" strokeLinecap="round" />
    </svg>
  )
}

export default IconArrowRight

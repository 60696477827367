import React from "react"
import { Link } from "react-router-dom"

import "./SpaceCreateCard.scss"

const SpaceCreateCard = () => {
  return (
    <div className="SpaceCreateCard">
      <Link to="/spaces/new">+</Link>
      <div className="SpaceCreateCard__title">
        <div> Start a new </div>
        <div> focus space</div>
      </div>
    </div>
  )
}

export default SpaceCreateCard

import React from "react"
import Heading from "~/components/Heading/Heading"
import PageContent from "~/components/PageContent/PageContent"

import "./ResetPassword.scss"

function PasswordReset(props) {
  const { email } = props

  return (
    <PageContent center={false}>
      <div className="ResetPassword">
        <Heading text="Check your inbox" />
        <span className="ResetPassword__description">
          A reset link has been send to {email}. Please follow the instructions
          to reset your password.
        </span>
      </div>
    </PageContent>
  )
}

export default PasswordReset

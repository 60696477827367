import React from "react"

import "~/components/Label/Label"
import "./TextField.scss"
import Label from "~/components/Label/Label"
import classNames from "classnames"

function TextField(props) {
  const {
    label,
    placeholder,
    style,
    autofocus,
    id,
    type,
    regex,
    name,
    disabled,
    maxLength,
    className,
  } = props

  const handleChange = (event) => {
    let input = event.target.value
    if (props.regex) {
      input = event.target.value.replace(regex, "")
    }

    if (props.valueChanged) {
      props.valueChanged(input)
    }
  }

  return (
    <div className={classNames("TextField", `TextField--${style}`, className)}>
      {label ? <Label text={label} htmlFor={id} /> : ""}
      <input
        autoFocus={autofocus}
        placeholder={placeholder}
        value={props.value}
        onChange={handleChange}
        id={id}
        type={type}
        name={name}
        disabled={disabled}
        maxLength={maxLength}
      />
    </div>
  )
}

TextField.defaultProps = {
  style: "dark",
  autofocus: false,
  type: "text",
  disabled: false,
  maxLength: null,
}

export default TextField

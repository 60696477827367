import React, { useState } from "react"

import "~/components/Label/Label"
import "./Checkbox.scss"
import Label from "~/components/Label/Label"

function Checkbox(props) {
  const [value, setValue] = useState(props.value)

  const handleChange = (event) => {
    setValue(event.target.checked)

    if (props.valueChanged) {
      props.valueChanged(event.target.checked)
    }
  }

  const { id, label } = props

  return (
    <div>
      <Label className="Checkbox__label-text" text={label} />

      <div className="Checkbox__switch">
        <input
          type="checkbox"
          id={id}
          className="Checkbox__switch-input"
          value={value}
          checked={value}
          onChange={handleChange}
        />
        <label htmlFor={id} className="Checkbox__switch-label"></label>
      </div>
    </div>
  )
}

export default Checkbox

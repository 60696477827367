import React from "react"

import "./SpaceNotFoundCard.scss"
import notFoundBgImg from "./not_found.png"

const SpaceNotFoundCard = () => {
  return (
    <div className="SpaceNotFoundCard">
      <div
        className="SpaceNotFoundCard__background"
        style={{ backgroundImage: `url(${notFoundBgImg})` }}
      >
        404
      </div>
    </div>
  )
}

export default SpaceNotFoundCard

import classNames from "classnames"
import React from "react"

import "./Label.scss"

function Label(props) {
  return (
    <label
      className={classNames("Label", props.className)}
      htmlFor={props.htmlFor}
    >
      {props.text}
    </label>
  )
}

export default Label

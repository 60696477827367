function getRandomInt(min, max) {
  return Math.floor(Math.random() * (max - min)) + min
}

export const generateRandomSpaceName = () => {
  const parts = [
    [
      "Lead",
      "Senior",
      "Product",
      "National",
      "Regional",
      "District",
      "Central",
      "Global",
      "Customer",
      "Investor",
      "Dynamic",
      "International",
      "Legacy",
      "Forward",
      "Internal",
      "Chief",
      "Direct",
      "Corporate",
      "Future",
      "Human",
      "Principal",
    ],
    [
      "Cake",
      "Cookie",
      "Pie",
      "Ice-Cream",
      "Pudding",
      "Sweet-Bread",
      "Cupcake",
      "Cheesecake",
      "Brownie",
      "Cobbler",
      "Ice-Cream-Cake",
      "Doughnut",
      "Frozen-Yogurt",
      "Parfait",
      "Sundae",
      "Trifle",
      "Cake-Pop",
      "Fruit-Cake",
      "Coffee-Cake",
      "Key-Lime-Pie",
    ],
    [
      "Supervisor",
      "Associate",
      "Executive",
      "Liaison",
      "Officer",
      "Manager",
      "Engineer",
      "Specialist",
      "Director",
      "Coordinator",
      "Administrator",
      "Architect",
      "Analyst",
      "Designer",
      "Planner",
      "Orchestrator",
      "Technician",
      "Developer",
      "Producer",
      "Consultant",
      "Assistant",
      "Facilitator",
      "Agent",
      "Representative",
      "Strategist",
    ],
  ]

  return parts
    .map((part) => {
      return part[getRandomInt(0, part.length - 1)]
    })
    .join("-")
    .toLowerCase()
}

export const getLocations = (includeBlank) => {
  const locations = (process.env.LOCATIONS || "Copenhagen;Odense")
    .split(";")
    .map((location) => {
      return {
        optionValue: location.toLowerCase(),
        optionLabel: location,
      }
    })
  const blank = { optionValue: "", optionLabel: includeBlank }
  return includeBlank ? [blank, ...locations] : locations
}

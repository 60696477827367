import classNames from "classnames"
import React from "react"

import "./PageWithSidebar.scss"

export default function PageWithSidebar(props) {
  const { className, ...rest } = props

  const usedClassName = classNames("PageWithSidebar", className)

  return <div className={usedClassName} {...rest} />
}

export const createImageFromInitials = (size, name) => {
  if (name == null) return
  const initials = getInitials(name)

  const canvas = document.createElement("canvas")
  const context = canvas.getContext("2d")

  canvas.width = canvas.height = size

  //background
  context.fillStyle = "#ed948e"
  context.fillRect(0, 0, size, size)

  //text
  context.fillStyle = "#ffffff"
  context.textBaseline = "middle"
  context.textAlign = "center"
  context.font = `${size / 2 - size / 10}px Hansen Grotesque`
  context.fillText(initials, size / 2, size / 2 + 15)

  return canvas.toDataURL()
}

export const getInitials = (name) => {
  let initials
  const nameSplit = name.split(" ")
  const nameLength = nameSplit.length
  if (nameLength > 1) {
    initials =
      nameSplit[0].substring(0, 1) + nameSplit[nameLength - 1].substring(0, 1)
  } else if (nameLength === 1) {
    initials = nameSplit[0].substring(0, 1)
  } else return

  return initials.toUpperCase()
}

import React from "react"
import User from "~/components/User/User"
import IconEdit from "../Icons/iconEdit"
import SettingsUsersTableRow from "../SettingsUsersTableRow/SettingsUsersTableRow"
import "./SettingsUsersTable.scss"

function SettingsUsersTable(props) {
  const userRows = props.users.map((user, index) => {
    return <SettingsUsersTableRow index={index} user={user} key={user.id} />
  })

  return (
    <table className="SettingsUsersTable">
      <thead>
        <tr>
          <th>Name</th>
          <th>Role</th>
          <th></th>
          <th></th>
        </tr>
      </thead>
      <tbody>{userRows}</tbody>
    </table>
  )
}

SettingsUsersTable.defaultProps = {
  users: [],
}

export default SettingsUsersTable

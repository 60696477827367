import { useEffect, useRef } from "react"
import { deserialize } from "jsonapi-deserializer"
import { useDispatch, useSelector } from "react-redux"

import consumer from "~/channels/consumer"
import { postEvent } from "~/utils/EventManager"
import { getUser, setCycle, setUser, getSpaceName, updateSpace } from "~/store"

export default function SpaceChannel() {
  const dispatch = useDispatch()
  const subscription = useRef(null)
  const user = useSelector(getUser)
  const spaceName = useSelector(getSpaceName)

  useEffect(() => {
    if (!spaceName || !user) return

    subscription.current = consumer.subscriptions.create(
      { channel: "SpaceChannel", spaceName },
      {
        connected() {
          dispatch(setUser({ ...user, space_name: spaceName }))
          postEvent(spaceName, "userConnected")
        },

        received(data) {
          const spaceData = deserialize(data["value"])
          if (spaceData.current_timer) {
            if (spaceData.current_timer.break) dispatch(setCycle("break"))
            else dispatch(setCycle("work"))
          }

          const eventType = spaceData.type
          if (eventType === "timerEnded") spaceData.current_timer = null

          dispatch(
            updateSpace({
              name: spaceData.name,
              changes: {
                current_timer: spaceData.current_timer,
                users: spaceData.users,
              },
            })
          )
        },
      }
    )
    return () => {
      subscription.current.unsubscribe()
    }
  }, [spaceName])

  return null
}

import React, { useState } from "react"
import Heading from "~/components/Heading/Heading"
import Button from "~/components/Button/Button"
import { useHistory } from "react-router-dom"
import TextField from "~/components/TextField/TextField"
import PageContent from "~/components/PageContent/PageContent"
import axios from "axios"
import { useDispatch } from "react-redux"
import { createAlert } from "~/store"
import "./InviteToOrganisation.scss"

function InviteToOrganisation() {
  const dispatch = useDispatch()
  const [userEmail, setUserEmail] = useState("")
  const [inviting, setInviting] = useState(false)
  const [firstInvite, setFirstInvite] = useState(true)
  let history = useHistory()

  const inviteToOrganisation = () => {
    setInviting(true)

    const token = document.querySelector("[name=csrf-token]").content

    axios
      .post(
        "/users/invitation",
        { invite: { email: userEmail } },
        { headers: { "X-CSRF-TOKEN": token } }
      )
      .then((_response) => {
        dispatch(
          createAlert({
            message: `${userEmail} has been invited!`,
            type: "success",
          })
        )
        setInviting(false)
        setUserEmail("")
        setFirstInvite(false)
      })
      .catch((error) => {
        dispatch(
          createAlert({
            message: error.response.data.error.join(", "),
            type: "error",
          })
        )
        setInviting(false)
      })
  }

  return (
    <PageContent>
      <div className="InviteToOrganisation">
        <Heading text="Invite collaborators" />

        <form>
          <TextField
            label="Invite by email"
            placeholder="example@domail.com"
            value={userEmail}
            valueChanged={setUserEmail}
            type="email"
            autofocus={true}
            name="email"
          />

          <div className="actions-wrapper mt-20 mb-20 float-right">
            <Button
              type="button"
              text="I'm done"
              style="primary outline mr-10"
              onClick={() => history.push("/settings/users")}
            />
            <Button
              type="submit"
              text={
                inviting
                  ? "Inviting..."
                  : firstInvite
                  ? "Send an invite"
                  : "Send another invite"
              }
              style="primary"
              onClick={inviteToOrganisation}
              disabled={inviting}
            />
          </div>
        </form>
      </div>
    </PageContent>
  )
}

export default InviteToOrganisation

import React, { useState } from "react"
import { NavLink } from "react-router-dom"
import { useDispatch } from "react-redux"
import { Redirect } from "react-router-dom"
import Footer from "~/components/Footer/Footer"
import Heading from "~/components/Heading/Heading"
import Button from "~/components/Button/Button"
import TextField from "~/components/TextField/TextField"
import PageContent from "~/components/PageContent/PageContent"
import "./SignUp.scss"
import axios from "axios"
import { setUser, createAlert } from "~/store"

function SignUp() {
  const dispatch = useDispatch()
  const [redirect, setRedirect] = useState(false)
  const [creating, setCreating] = useState(false)
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [passwordConfirmation, setPasswordConfirmation] = useState("")

  const userSignUp = () => {
    setCreating(true)

    const params = {
      user: {
        name: name,
        email: email,
        password: password,
        password_confirmation: passwordConfirmation,
      },
    }

    axios
      .post("/api/users", params)
      .then((response) => {
        setRedirect(true)
        const userData = response.data["data"]["attributes"]
        dispatch(setUser(userData))
      })
      .catch((error) => {
        dispatch(
          createAlert({
            message: error.response.data.error.join(", "),
            type: "error",
          })
        )
        setCreating(false)
      })
  }

  return redirect ? (
    <Redirect to={`/`} />
  ) : (
    <PageContent>
      <div className="SignUp">
        <Heading text="Sign up" />

        <form>
          <TextField
            label="What's your full name?"
            value={name}
            valueChanged={(value) => {
              setName(value)
            }}
            regex={/[&/\\#,$~%.":*{}]/g}
            maxLength="50"
            id="full-name"
          />
          <TextField
            label="Email address"
            type="email"
            value={email}
            valueChanged={(value) => {
              setEmail(value)
            }}
            id="email"
          />

          <TextField
            label="Password"
            type="password"
            value={password}
            valueChanged={(value) => {
              setPassword(value)
            }}
            id="password"
          />

          <TextField
            label="Repeat password"
            type="password"
            value={passwordConfirmation}
            valueChanged={(value) => {
              setPasswordConfirmation(value)
            }}
            id="password-confirmation"
          />

          <div className="SignUp__actions mt-20 mb-20">
            <span>
              By signing up, you agree to our{" "}
              <a
                href="https://www.abtion.com/privatlivspolitik"
                target="_blank"
              >
                Privacy Policy
              </a>
            </span>
            <Button
              text="Continue"
              style="primary"
              onClick={userSignUp}
              disabled={creating}
            />
          </div>
        </form>

        <div className="SignUp__sign-in">
          <NavLink to="/" className="SignUp__sign-in-link">
            Sign in to <span className="font-weight-bold">Pomodoro</span>
          </NavLink>
        </div>
      </div>
      <Footer style="light" />
    </PageContent>
  )
}

export default SignUp

import React from "react"

function IconDashboard() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.1437 6.62802C10.9303 4.66702 11.3236 3.68652 12 3.68652C12.6763 3.68652 13.0696 4.66702 13.8562 6.62802L13.8928 6.71934C14.3372 7.82721 14.5594 8.38114 15.0123 8.71783C15.4651 9.05452 16.0596 9.10776 17.2485 9.21423L17.4634 9.23348C19.4092 9.40774 20.3822 9.49488 20.5903 10.1139C20.7985 10.7328 20.076 11.3902 18.6309 12.7049L18.1487 13.1436C17.4172 13.8092 17.0514 14.1419 16.8809 14.5781C16.8491 14.6594 16.8227 14.7428 16.8018 14.8276C16.6897 15.2822 16.7968 15.765 17.0111 16.7304L17.0777 17.031C17.4714 18.8053 17.6682 19.6925 17.3246 20.0751C17.1961 20.2181 17.0292 20.3211 16.8438 20.3717C16.3476 20.5071 15.6431 19.933 14.2342 18.7849C13.309 18.031 12.8464 17.6541 12.3153 17.5693C12.1064 17.5359 11.8935 17.5359 11.6846 17.5693C11.1535 17.6541 10.6909 18.031 9.76577 18.7849C8.35681 19.933 7.65234 20.5071 7.15614 20.3717C6.97072 20.3211 6.80381 20.2181 6.67538 20.0751C6.33171 19.6925 6.52854 18.8053 6.92222 17.031L6.98889 16.7304C7.2031 15.765 7.31021 15.2822 7.19815 14.8276C7.17725 14.7428 7.15081 14.6594 7.11901 14.5781C6.94854 14.1419 6.58279 13.8092 5.85128 13.1436L5.369 12.7049C3.92395 11.3902 3.20143 10.7328 3.40961 10.1139C3.61779 9.49488 4.5907 9.40774 6.53651 9.23348L6.75145 9.21423C7.94036 9.10775 8.53482 9.05452 8.98767 8.71783C9.44052 8.38114 9.66272 7.8272 10.1071 6.71934L10.1437 6.62802Z"
        stroke="#36244D"
        strokeWidth="2"
      />
    </svg>
  )
}

export default IconDashboard

import React from "react"
import axios from "axios"
import { NavLink } from "react-router-dom"
import { useSelector } from "react-redux"
import { getUser } from "~/store"
import IconArrowRight from "~/components/Icons/IconArrowRight"
import "./SettingsMenu.scss"

function SettingsMenu() {
  const user = useSelector(getUser)

  const logUserOut = (e) => {
    e.preventDefault()
    const token = document.querySelector("[name=csrf-token]").content

    axios
      .delete("/users/sign_out", { headers: { "X-CSRF-TOKEN": token } })
      .then((_response) => {
        window.location.reload(false)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  return (
    <nav className="SettingsMenu">
      <NavLink
        to="/settings/profile"
        className="SettingsMenu__link"
        activeClassName="SettingsMenu__link--selected"
      >
        Profile
        <IconArrowRight />
      </NavLink>

      {user.organisation_name && (
        <>
          <NavLink
            to="/settings/organisation"
            className="SettingsMenu__link"
            activeClassName="SettingsMenu__link--selected"
          >
            Organisation
            <IconArrowRight />
          </NavLink>

          <NavLink
            to="/settings/users"
            className="SettingsMenu__link"
            activeClassName="SettingsMenu__link--selected"
          >
            Users
            <IconArrowRight />
          </NavLink>
        </>
      )}

      <NavLink to="/" className="SettingsMenu__link" onClick={logUserOut}>
        Log out
      </NavLink>
    </nav>
  )
}

export default SettingsMenu

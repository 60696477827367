import React from "react"

import "./Tooltip.scss"

function Tooltip(props) {
  return (
    <div className={`tooltip ${props.className}`}>
      <span className="tooltiptext">{props.text}</span>
      {props.children}
    </div>
  )
}

export default Tooltip

import React, { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import { getUser } from "~/store"
import axios from "axios"
import Sidebar from "~/components/Sidebar/Sidebar"
import Footer from "~/components/Footer/Footer"
import Heading from "~/components/Heading/Heading"
import SettingsMenu from "~/components/SettingsMenu/SettingsMenu"
import RollingTomato from "~/components/RollingTomato/RollingTomato"
import SettingsUsersTable from "~/components/SettingsUsersTable/SettingsUsersTable"
import Button from "~/components/Button/Button"
import PageContent from "~/components/PageContent/PageContent"
import PageWithSidebar from "~/components/PageWithSidebar/PageWithSidebar"
import "./SettingsUsers.scss"

function SettingsUsers() {
  const user = useSelector(getUser)
  const [users, setUsers] = useState([])
  const [loaded, setLoaded] = useState(false)
  let history = useHistory()

  useEffect(() => {
    axios
      .get("/api/organisations/users")
      .then((response) => {
        setUsers(response.data["data"])
        setLoaded(true)
      })
      .catch((error) => {
        console.log(error)
      })
  }, [])

  return (
    <PageWithSidebar>
      <PageContent center={false}>
        <div className="SettingsUsers">
          <Heading text="Settings" />

          <div className="SettingsUsers__content">
            <SettingsMenu />

            {!loaded ? (
              <div className="SettingsUsers__loader">
                <RollingTomato />
              </div>
            ) : (
              <div className="SettingsUsers__table">
                {user.admin && process.env.SIGN_IN_WITH_EMAIL === "true" && (
                  <Button
                    type="button"
                    text="Invite users"
                    style="primary mt-20 float-right"
                    onClick={() => history.push("/organisations/invite")}
                  />
                )}
                <SettingsUsersTable users={users} />
              </div>
            )}
          </div>
        </div>
        <Footer style="light" />
      </PageContent>
      <Sidebar style="primary" />
    </PageWithSidebar>
  )
}

export default SettingsUsers

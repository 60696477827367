export const filterUsers = (users, location) => {
  return users.filter(
    (user) => location == "" || user.attributes.location == location
  )
}

export const sortUsers = (users) => {
  return users.sort((a, b) => {
    const a_count = a.relationships["week_timers"].data.length
    const b_count = b.relationships["week_timers"].data.length

    if (a_count < b_count) {
      return 1
    }

    if (a_count > b_count) {
      return -1
    }

    return 0
  })
}

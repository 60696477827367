import React from "react"
import RollingTomato from "../../components/RollingTomato/RollingTomato"

import "./SpaceLoadingCard.scss"

const SpaceLoadingCard = () => {
  return (
    <div className="SpaceLoadingCard">
      <RollingTomato />
    </div>
  )
}

export default SpaceLoadingCard

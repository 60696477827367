import React, { useState } from "react"
import { NavLink } from "react-router-dom"
import Heading from "~/components/Heading/Heading"
import Button from "~/components/Button/Button"
import TextField from "~/components/TextField/TextField"
import axios from "axios"
import { useDispatch } from "react-redux"
import { createAlert } from "~/store"
import ResetPassword from "~/pages/ResetPassword/ResetPassword"
import PageContent from "~/components/PageContent/PageContent"

import "./NewPassword.scss"

function NewPassword() {
  const dispatch = useDispatch()
  const [email, setEmail] = useState("")
  const [emailSent, setEmailSent] = useState(false)
  const [sendingEmail, setSendingEmail] = useState(false)

  const sendEmail = () => {
    setSendingEmail(true)

    const token = document.querySelector("[name=csrf-token]").content

    axios
      .post(
        "/users/password",
        { user: { email: email } },
        { headers: { "X-CSRF-TOKEN": token } }
      )
      .then((_response) => {
        setEmailSent(true)
      })
      .catch((error) => {
        dispatch(
          createAlert({
            message: error.response.data.error,
            type: "error",
          })
        )
        setSendingEmail(false)
      })
  }

  return emailSent ? (
    <ResetPassword email={email} />
  ) : (
    <PageContent center={false}>
      <div className="NewPassword">
        <Heading text="Forgot your password?" />

        <div className="NewPassword__description">
          Please enter your email address to reset your password.
        </div>

        <form>
          <TextField
            label="Email address"
            id="email"
            type="email"
            autofocus={true}
            value={email}
            valueChanged={(value) => {
              setEmail(value)
            }}
          />

          <div className="NewPassword__actions mt-20 mb-20">
            <Button
              text="Send email"
              style="primary float-right"
              onClick={sendEmail}
              disabled={sendingEmail}
            />
          </div>
        </form>

        <div className="NewPassword__sign-in">
          <NavLink to="/" className="NewPassword__secondary-link">
            Sign in to <span className="font-weight-bold">Pomodoro</span>
          </NavLink>
        </div>
      </div>
    </PageContent>
  )
}

export default NewPassword

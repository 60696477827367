export const calculateDurationLeft = (timer) => {
  const startedAt = new Date(timer.started_at)
  const duration = timer.duration
  const endsAt = new Date(startedAt.getTime() + duration * 1000)
  return Math.max(0, endsAt.getTime() / 1000 - new Date().getTime() / 1000)
}

export const calculatePercentage = (timer) => {
  const duration = timer.duration
  return Math.min(
    Math.max(0, 100 - (calculateDurationLeft(timer) * 100) / duration),
    100
  )
}

import React, { useState } from "react"

import "~/components/Label/Label"
import "./Dropdown.scss"
import Label from "~/components/Label/Label"

function Dropdown(props) {
  const { label, options, valueChanged, ariaLabel } = props
  const [value, setValue] = useState(props.value)

  const handleChange = (event) => {
    setValue(event.target.value)

    if (valueChanged) {
      valueChanged(event.target.value)
    }
  }

  return (
    <div>
      {label && <Label text={label} />}
      <select
        aria-label={ariaLabel}
        className="Dropdown__select"
        onChange={handleChange}
        value={value}
      >
        {options.map((option, index) => {
          return (
            <option value={option.optionValue} key={index}>
              {option.optionLabel}
            </option>
          )
        })}
      </select>
    </div>
  )
}

Dropdown.defaultProps = {
  options: [],
}

export default Dropdown

import classNames from "classnames"
import React from "react"

import "./PageContent.scss"

export default function PageContent(props) {
  const { className, style = "dark", center = true, ...rest } = props

  const usedClassName = classNames(
    "PageContent",
    `PageContent--${style}`,
    {
      "PageContent--center": center,
    },
    className
  )

  return <div className={usedClassName} {...rest} />
}

import {
  configureStore,
  createSlice,
  createAsyncThunk,
  createSelector,
} from "@reduxjs/toolkit"
import axios from "axios"

/* ===== ===== CYCLE ===== ===== */

const cycleSlice = createSlice({
  name: "cycle",
  initialState: {
    currentCycle: "work",
  },
  reducers: {
    setCycle: (state, action) => {
      state.currentCycle = action.payload
    },
    switchCycle: (state) => {
      const nextCycle = state.currentCycle === "work" ? "break" : "work"
      state.currentCycle = nextCycle
    },
  },
})

export const { setCycle, switchCycle } = cycleSlice.actions
export const getCycle = (state) => state.cycleReducer.currentCycle

export const cycleReducer = cycleSlice.reducer

/* ===== ===== USER ===== ===== */

export const fetchUser = createAsyncThunk("user/fetchUser", async () => {
  const response = await axios.get("/api/users/me")
  return response.data["data"]["attributes"]
})

const userSlice = createSlice({
  name: "user",
  initialState: {
    user: null,
    fetching: false,
  },
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUser.fulfilled, (state, action) => {
        state.user = action.payload
        state.fetching = false
      })
      .addCase(fetchUser.rejected, (state) => {
        state.user = null
        state.fetching = false
      })
      .addCase(fetchUser.pending, (state) => {
        state.fetching = true
      })
  },
})

export const { setUser } = userSlice.actions
export const getUser = (state) => state.userReducer.user
export const getUserFetching = (state) => state.userReducer.fetching

export const userReducer = userSlice.reducer

/* ===== ===== SPACES ===== ===== */

const spacesSlice = createSlice({
  name: "spaces",
  initialState: {
    spaces: null,
    spaceName: null,
  },
  reducers: {
    setSpaces: (state, action) => {
      state.spaces = action.payload
    },
    setSpaceName: (state, action) => {
      state.spaceName = action.payload
    },
    updateSpace: (state, action) => {
      const { name, changes } = action.payload
      const indexOfSpace = state.spaces.findIndex(
        (space) => space.name === name
      )
      let updatedSpace = { ...state.spaces[indexOfSpace], ...changes }
      state.spaces.splice(indexOfSpace, 1, updatedSpace)
    },
  },
})

export const { setSpaces, setSpaceName, updateSpace } = spacesSlice.actions

export const getSpaces = (state) => state.spacesReducer.spaces
export const getSpaceName = (state) => state.spacesReducer.spaceName
export const getCurrentSpace = createSelector(
  getSpaces,
  getSpaceName,
  (spaces, spaceName) =>
    spaces ? spaces.find((space) => space.name === spaceName) : null
)

export const spacesReducer = spacesSlice.reducer

/* ===== ===== NOTIFICATIONS ===== ===== */

export const AlertSlice = createSlice({
  name: "alert",
  initialState: {
    alerts: [],
  },
  reducers: {
    createAlert: (state, action) => {
      state.alerts.push({
        message: action.payload.message,
        type: action.payload.type,
      })
    },
  },
})

export const { createAlert } = AlertSlice.actions
export const getAlerts = (state) => state.alertsReducer.alerts
export const alertsReducer = AlertSlice.reducer

/* ===== ===== COUNTDOWN ===== ===== */

// NOT YET IN USE

// const countdownSlice = createSlice({
//   name: "countdown",
//   initialState: {
//     countdownInterval: null,
//   },
//   reducers: {
//     setInterval: (state) => {
//       state.countdownInterval
//     },
//   },
// })

/* ===== ===== STORE ===== ===== */

export const reducer = {
  cycleReducer: cycleReducer,
  userReducer: userReducer,
  spacesReducer: spacesReducer,
  alertsReducer: alertsReducer,
}

export const store = configureStore({
  reducer,
})

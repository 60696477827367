import React, { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import axios from "axios"

import Sidebar from "~/components/Sidebar/Sidebar"
import Heading from "~/components/Heading/Heading"
import Dropdown from "~/components/Dropdown/Dropdown"
import DashboardTable from "../../components/DashboardTable/DashboardTable"
import RollingTomato from "../../components/RollingTomato/RollingTomato"
import { sortUsers, filterUsers } from "~/utils/DashboardUtils"
import PageContent from "~/components/PageContent/PageContent"
import PageWithSidebar from "~/components/PageWithSidebar/PageWithSidebar"
import { getLocations } from "~/utils/Locations"
import { getUser } from "~/store"

import "./Dashboard.scss"

function Dashboard() {
  const user = useSelector(getUser)
  const [users, setUsers] = useState([])
  const [location, setLocation] = useState(user.location ? user.location : "")
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    axios
      .get("/api/users")
      .then((response) => {
        setUsers(response.data["data"])
        setLoaded(true)
      })
      .catch((error) => {
        console.log(error)
      })
  }, [])

  const filteredUsers = sortUsers(filterUsers(users, location))

  return (
    <PageWithSidebar>
      <PageContent>
        <div className="Dashboard">
          <Heading text="Dashboard" />
          <div className="Dashboard__header">
            <h2>Team members</h2>
            <Dropdown
              value={location}
              options={getLocations("All")}
              valueChanged={setLocation}
            />
          </div>
          {!loaded ? (
            <div className="Dashboard__loader">
              <RollingTomato />
            </div>
          ) : (
            <div className="Dashboard__table">
              <DashboardTable users={filteredUsers} />
            </div>
          )}
        </div>
      </PageContent>
      <Sidebar style="primary" />
    </PageWithSidebar>
  )
}

export default Dashboard

import { useEffect, useRef } from "react"
import { deserialize } from "jsonapi-deserializer"
import { useSelector, useDispatch } from "react-redux"

import consumer from "~/channels/consumer"
import { postEvent } from "~/utils/EventManager"
import { setSpaces, getUser } from "~/store"

export default function SpaceChannel() {
  const dispatch = useDispatch()
  const subscription = useRef(null)
  const user = useSelector(getUser)
  const organisationName = user.organisation_name

  useEffect(() => {
    subscription.current = consumer.subscriptions.create(
      { channel: "SpacesChannel", organisationName },
      {
        connected() {
          postEvent(null, "userConnected")
        },
        received(data) {
          const parsedSpaces = deserialize(data["value"])
          dispatch(setSpaces(parsedSpaces))
        },
      }
    )
    return () => {
      subscription.current.unsubscribe()
    }
  }, [])

  return null
}
